
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import naics from 'naics';
import NAICSCode from '@/common/NAICSCode.vue';

import { ProfileInstance } from '@/interface/profile.interface';
import { NAICS, STATE_NAME } from '@/interface/other.interface';

import ModalEditAvatar from '@/modals/EditAvatar.vue';

import Avatar from '@/common/Avatar.vue';
import IconsService from '@/icons/Service.vue';

import requests from '@/requests';
import methods from '@/methods';

@Options({
  components: {
    NAICSCode,
    Avatar,
    IconsService,
    ModalEditAvatar,
  },
  computed: {
    ...mapState(['profile']),
  },
})
export default class SettingsPageProfile extends Vue {
  public STATE_NAME = STATE_NAME;

  public editProfileData: ProfileInstance | any = { };

  public isShowModalEditAvatar = false;

  public NAICSTitle = '';

  public selectState(state: STATE_NAME): void {
    this.editProfileData.company_state = state;
  }

  public handleChangeProfile(key: keyof ProfileInstance, data: string): void {
    if (this.$store.state.profile![key] !== data) {
      this.editProfileData[key] = data;
    } else {
      delete this.editProfileData[key];
    }
  }

  public handleChangeNAICS(naics: NAICS): void {
    const code = String(this.$store.state.profile?.naics_code);

    this.NAICSTitle = naics.title || '';

    if (code !== naics.code) {
      this.editProfileData.naics_code = naics.code;
    } else {
      delete this.editProfileData.naics_code;
    }
  }

  public saveChanges(): void {
    this.changeShowModalLoader(true);

    requests.profile.patchProfile(this.editProfileData)
      .then((res) => {
        methods.store.setupProfile(res.data);
        this.editProfileData = {};
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  public resetPassword(): void {
    this.$store.commit('changeData', this.$store.state.profile?.email);
    this.$router.push('/confirm-account');
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    const code = this.$store.state.profile?.naics_code;

    if (naics) {
      this.NAICSTitle = naics.Industry.from(String(code))?.title || '';
    }
  }
}
