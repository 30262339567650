
/* eslint-disable camelcase, max-len */
import { Options, Vue } from 'vue-class-component';
import { shallowRef } from 'vue';
import { mapState } from 'vuex';

import {
  Cropper,
  CircleStencil,
  CropperResult,
  Coordinates,
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import IconDefaultAvatar from '@/icons/other/DefaultAvatar.vue';

import Modal from '@/common/base/Modal.vue';
import requests from '@/requests';
import { toFormData } from '@/utils';
import methods from '@/methods';
import { CropperInstance } from '@/interface/other.interface';

interface AvatarData {
  profile_picture?: string | null;
  profile_picture_metadata?: Coordinates | null;
  name?: string;
}

@Options({
  components: {
    Modal,
    Cropper,
    IconDefaultAvatar,
  },
  computed: {
    ...mapState(['profile']),
  },
  emits: {
    close: Boolean,
  },
})
export default class ModalChangeSubscription extends Vue {
  declare $refs: {
    cropper: CropperInstance;
    form: HTMLFormElement;
    input: HTMLInputElement;
  }

  public CircleStencil = shallowRef(CircleStencil);

  public loader = false;

  public showDragNDrop = false;

  public avatar: AvatarData = {};

  private indexAvatar: AvatarData = {};

  public handleCropperChange(res: CropperResult) {
    this.avatar.profile_picture_metadata = { ...res.coordinates };
  }

  public uploadFile(e: Event): void {
    const file = (e.target as HTMLInputElement).files?.item(0);

    if (file && file.type.includes('image')) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.avatar.profile_picture = e.target!.result as string;
        this.avatar.name = file.name;

        this.loader = true;
      };

      reader.readAsDataURL(file);
    }

    this.resetForm();
  }

  public save(): void {
    this.changeShowModalLoader(true);

    if (this.avatar.profile_picture !== this.indexAvatar.profile_picture) {
      const file = { profile_picture: methods.base64ToFile(this.avatar.profile_picture!, this.avatar.name!) };

      requests.profile.patchProfile(toFormData(file))
        .then(() => {
          this.updateProfilePicture({ profile_picture_metadata: this.avatar.profile_picture_metadata });
        })
        .catch(() => {
          this.changeShowModalLoader(false);
        });
    } else {
      this.updateProfilePicture({ profile_picture_metadata: this.avatar.profile_picture_metadata });
    }
  }

  public uploadLocalImage(): void {
    this.$refs.input.click();
  }

  public resetForm(): void {
    this.$refs.form.reset();
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  get disabledSave(): boolean {
    return (
      this.indexAvatar === this.avatar.profile_picture
      || JSON.stringify(this.indexAvatar.profile_picture_metadata) === JSON.stringify(this.avatar.profile_picture_metadata));
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  private updateProfilePicture(data: any): void {
    requests.profile.patchProfile(data)
      .then((res) => {
        methods.store.setupProfile(res.data);
        this.closeModal();
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  created(): void {
    const avatar = this.$store.state.profile?.profile_picture || '';

    if (avatar) {
      const coordinates = this.$store.state.profile?.profile_picture_metadata;

      this.loader = true;
      this.avatar.profile_picture = avatar;
      this.avatar.profile_picture_metadata = coordinates;

      this.indexAvatar = { ...this.avatar };
    }
  }

  mounted(): void {
    const coordinates = this.avatar.profile_picture_metadata;

    if (coordinates) this.$refs.cropper.setCoordinates(coordinates);
  }
}
